'use client';
import cn from 'classnames';
import styles from './HeaderMobile.module.scss';
import { Button } from '@/atoms/Button';
import { useTranslations } from 'next-intl';
import { BurgerButton } from '@/atoms/BurgerButton';
import { useRedirectToAccount } from '@/hooks/useRedirectToAccount';
import { selectShowBurgerMenu, setShowBurgerMenu } from '@/store';
import { useDispatch, useSelector } from 'react-redux';

type HeaderMobileProps = {
  className?: string;
};

export function HeaderMobile({ className }: HeaderMobileProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const common = useTranslations('common');
  const { redirect } = useRedirectToAccount();
  const dispatch = useDispatch();
  const showBurgerMenu = useSelector(selectShowBurgerMenu);

  const handleBurgerClick = () => {
    dispatch(setShowBurgerMenu(!showBurgerMenu));
  };

  return (
    <header className={wrapperClassName}>
      <BurgerButton onClick={handleBurgerClick} active={showBurgerMenu} />
      <div className={styles.button}>
        <Button text={common('signIn')} onClick={redirect} variant="dark" />
      </div>
    </header>
  );
}
