import { usePathname, useRouter } from '@/i18n/navigation';
import { useLocale } from 'next-intl';

const SUPPORTED_LOCALES = {
  en: 'ru',
  ru: 'en',
} as const;

export const useChangeLocale = () => {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  const changeLocale = () => {
    const newLocale =
      SUPPORTED_LOCALES[locale as keyof typeof SUPPORTED_LOCALES];
    router.replace(pathname, { locale: newLocale });
  };

  return { changeLocale };
};
