'use client';
import styles from './BurgerButton.module.scss';
import cn from 'classnames';

type BurgerButtonProps = {
  className?: string;
  onClick: () => void;
  active?: boolean;
};

export function BurgerButton({
  className,
  onClick,
  active = false,
}: BurgerButtonProps) {
  const wrapperClassName = cn(styles.burger, className);

  const handleBurgerClick = () => {
    onClick();
  };

  if (active) {
    return (
      <img
        src="/svg/cross.svg"
        alt="menu"
        className={wrapperClassName}
        onClick={handleBurgerClick}
      />
    );
  }

  return (
    <img
      src="/svg/burger.svg"
      alt="menu"
      className={wrapperClassName}
      onClick={handleBurgerClick}
    />
  );
}
