import cn from 'classnames';
import styles from './Logo.module.scss';

type LogoProps = {
  className?: string;
  onClick?: () => void;
  withText?: boolean;
  textClass?: string;
  iconClass?: string;
  variant?: 'light' | 'dark';
};

export function Logo({
  className,
  onClick,
  withText,
  textClass,
  iconClass,
  variant = 'dark',
}: LogoProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const imageClassName = cn(styles.image, iconClass);
  const textClassName = cn(
    styles.text,
    {
      [styles.light]: variant === 'light',
    },
    textClass,
  );

  if (withText) {
    return (
      <div className={wrapperClassName} onClick={onClick}>
        <img src="/png/logo.png" alt="logo" className={imageClassName} />
        <span className={textClassName}>Workice</span>
      </div>
    );
  }

  return (
    <img
      className={imageClassName}
      src="/png/logo.png"
      alt="logo"
      onClick={onClick}
    />
  );
}
