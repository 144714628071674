'use client';
import { useRouter } from 'next/navigation';

export const useRedirectToAccount = () => {
  const { push } = useRouter();

  const redirect = () => {
    push('/account');
  };

  return { redirect };
};
