'use client';
import cn from 'classnames';
import styles from './Header.module.scss';
import { Button } from '@/atoms/Button';
import { useRouter } from 'next/navigation';
import { useState, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { useRedirectToAccount } from '@/hooks/useRedirectToAccount';
import { Logo } from '@/atoms/Logo';
import { useNavigationLinks } from '@/hooks/useNavigationLinks';

type HeaderProps = {
  className?: string;
};

type HeaderAnchorProps = {
  text: string;
  link: string;
};

export function Header({ className }: HeaderProps) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.scrolled]: isScrolled,
    },
    className,
  );
  const { push } = useRouter();
  const common = useTranslations('common');
  const { redirect } = useRedirectToAccount();

  const navigation = useNavigationLinks();

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    }

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={wrapperClassName}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.controls}>
        <Logo
          onClick={() => push('/')}
          withText
          textClass={cn(styles.logoText, {
            [styles.hidden]: isScrolled,
            [styles.visible]: isHovered,
          })}
        />
        <div
          className={cn(styles.links, {
            [styles.hidden]: isScrolled,
            [styles.visible]: isHovered,
          })}
        >
          {navigation.map((item) => (
            <HeaderAnchor key={item.id} text={item.title} link={item.href} />
          ))}
        </div>
      </div>
      <div className={styles.cta}>
        <Button text={common('signIn')} onClick={redirect} variant="dark" />
      </div>
    </header>
  );
}

const HeaderAnchor = ({ text, link }: HeaderAnchorProps) => {
  const handleClick = () => {
    const element = document.getElementById(link);

    if (element) {
      window.history.pushState({}, '', `#${link}`);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleClick();
    }
  };

  return (
    <div
      className={styles.link}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="link"
      tabIndex={0}
      aria-label={text}
    >
      {text}
    </div>
  );
};
