import { createAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';

export const setShowBurgerMenu = createAction<boolean>(
  ACTIONS.SHOW_BURGER_MENU,
);

export const setShowSidebarMenu = createAction<boolean>(
  ACTIONS.SHOW_SIDEBAR_MENU,
);
