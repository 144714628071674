import { BLOCK_IDS } from '@/constants';
import { useTranslations } from 'next-intl';

export function useNavigationLinks() {
  const t = useTranslations('header.navigation');

  const navigationLinks = [
    { id: BLOCK_IDS.HOME, title: t(BLOCK_IDS.HOME), href: BLOCK_IDS.HOME },
    {
      id: BLOCK_IDS.FEATURES,
      title: t(BLOCK_IDS.FEATURES),
      href: BLOCK_IDS.FEATURES,
    },
    {
      id: BLOCK_IDS.PRICING,
      title: t(BLOCK_IDS.PRICING),
      href: BLOCK_IDS.PRICING,
    },
    { id: BLOCK_IDS.ABOUT, title: t(BLOCK_IDS.ABOUT), href: BLOCK_IDS.ABOUT },
    {
      id: BLOCK_IDS.FEEDBACK,
      title: t(BLOCK_IDS.FEEDBACK),
      href: BLOCK_IDS.FEEDBACK,
    },
  ];

  return navigationLinks;
}
